import { addFirestoreDoc, createBatch, firestoreDeleteField, onDocSnapshotHandler } from '@/firebase/firestore'
import { getUid } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import { setLocalStorage } from '@/helpers/localstorage'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import { prepareDateFields } from '@/utils/prepareDateFields'
import router from '../router'
import packageData from './../../package.json'

export default {
  state: {
    appVersion: packageData.version,
    firebaseQuotaReached: false,
    online: true,
    scrollAllowed: true,
    clickedButton: null,
    dataBarIsOpen: false,
    menuIsOpen: false,
    processing: false,
    maintenance: false,
    maintenanceUnsubscribe: null,
    sessionId: null,
    openedLink: null,
    showLoader: false,
    showResize: false,
    loadingPage: false,
    appShow: {
      editUserPic: false,
      uploadUserPic: false,
      feedback: false,
      payWall: false
    },
    loadedAll: {
      accountsActive: false,
      accountsArchived: false,
      banks: false,
      cardsActive: false,
      cardsArchived: false,
      moneyBoxes: false,
      news: false,
      tagsActive: false,
      tagsArchived: false,
      tagsCollections: false,
      transactions: false,
      userStats: false
    }
  },
  mutations: {
    setOnline(state, status) {
      state.online = status
    },
    setScrollAllowed(state, allowed) {
      state.scrollAllowed = allowed
    },
    setClickedButton(state, value) {
      state.clickedButton = value
      setTimeout(() => {
        state.clickedButton = null
      }, 0)
    },
    setDataBarIsOpen(state, value) {
      state.dataBarIsOpen = value
    },
    setMenuIsOpen(state, value) {
      state.menuIsOpen = value
    },
    setProcessing(state, processing) {
      state.processing = processing
    },
    setAppShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.appShow[field] = show[field]
        }
      }
    },
    setMaintenance(state, data) {
      state.maintenance = data
    },
    setMaintenanceUnsubscribe(state, value) {
      state.maintenanceUnsubscribe = value
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId
    },
    setOpenedLink(state, value) {
      state.openedLink = value
    },
    setShowLoader(state, val) {
      state.showLoader = val
    },
    setShowResize(state, value) {
      state.showResize = value
    },
    setLoadingPage(state, value) {
      state.loadingPage = value
    },
    setLoadedAll(state, { field, value }) {
      if (field) {
        state.loadedAll[field] = value
      }
    },
    clearAppShow(state) {
      state.appShow = {
        editUserPic: false,
        uploadUserPic: false,
        feedback: false,
        payWall: false
      }
    },
    clearInfo(state) {
      state.firebaseQuotaReached = false
      state.scrollAllowed = true
      state.clickedButton = null
      state.dataBarIsOpen = false
      state.menuIsOpen = false
      state.processing = false
      state.maintenance = false
      state.maintenanceUnsubscribe = null
      state.sessionId = null
      state.showLoader = false
      state.showResize = false
      state.loadingPage = false
      state.appShow = {
        editUserPic: false,
        uploadUserPic: false,
        feedback: false,
        payWall: false
      }
      state.loadedAll = {
        accountsActive: false,
        accountsArchived: false,
        banks: false,
        cardsActive: false,
        cardsArchived: false,
        moneyBoxes: false,
        news: false,
        tagsActive: false,
        tagsArchived: false,
        tagsCollections: false,
        transactions: false,
        userStats: false
      }
    }
  },
  actions: {
    async setShow({ commit, dispatch }, show) {
      if (!show) { return }

      await dispatch('setCloseAllMenus')
      if (show === 'feedback') {
        commit('setAppShowField', { feedback: true })
      } else if (show === 'userpic') {
        commit('setAppShowField', { editUserPic: true })
      }
    },
    showToast(context, { type, text }) {
      if (!type || !text) { return }

      const { toastify } = useNotifications()

      if (type === 'info') {
        toastify.info(text)
      } else if (type === 'warning') {
        toastify.warning(text)
      } else if (type === 'error') {
        toastify.error(text)
      }
    },
    setMenuIsOpenAction({ commit, dispatch }, value) {
      commit('setMenuIsOpen', value)
      dispatch('setScrollAllowedAction', !value)
    },
    setCloseAllMenus({ commit, dispatch }) {
      dispatch('setMenuIsOpenAction', false)
      commit('setDataBarIsOpen', false)
      commit('clearTransactionsShow')
      commit('clearAccountsShow')
      commit('clearCardsShow')
      commit('clearSpreadsShow')
      commit('clearMoneyBoxesShow')
      commit('clearTagsShow')
      commit('clearTagsCollectionsShow')
      commit('clearSettingsShow')
      commit('clearBanksShow')
      commit('clearAppShow')
    },
    setScrollAllowedAction({ commit, getters }, allowed) {
      if (getters.lessonStep) { allowed = false }
      commit('setScrollAllowed', allowed)
    },
    async createSessionId({ commit }) {
      if (!getUid()) { return }

      const crypto = require('crypto')
      const localStorageName = 'blue-money-box-com-sessionId-' + getUid()
      const sessionId = crypto.randomBytes(20).toString('hex')

      await setLocalStorage({ name: localStorageName, data: null, string: sessionId })
      commit('setSessionId', sessionId)
    },
    async subscribeToMaintenance({ commit, dispatch }) {
      const t = tracePerformance('subscribeToMaintenance')
      t.start()

      try {
        const unsubscribe = onDocSnapshotHandler({
          collectionName: 'settings',
          docName: 'maintenance',
          successHandler: async (maintenance) => {
            if (maintenance.exists()) {
              const data = prepareDateFields(maintenance.data())
              await commit('setMaintenance', data.closed)
            }
            await commit('setMaintenanceUnsubscribe', unsubscribe)
          },
          errorHandler: async (error) => {
            commit('setError', error)
            dispatch('logoutAndGoToLogin', { from: 'maintenance-subscription-error-handler' })
          }
        })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'subscribeToMaintenance', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async unsubscribeMaintenance({ dispatch, getters }) {
      const t = tracePerformance('unsubscribeMaintenance')
      t.start()

      try {
        const maintenanceUnsubscribe = getters.maintenanceUnsubscribe
        if (maintenanceUnsubscribe) {
          await maintenanceUnsubscribe()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unsubscribeMaintenance', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async unsubscribeAll({ dispatch }) {
      const t = tracePerformance('unsubscribeAll')
      t.start()

      try {
        await dispatch('unsubscribeMaintenance')
        await dispatch('unsubscribeNews')
        await dispatch('unsubscribeLogs')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unsubscribeAll', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async createFeedback({ commit, dispatch, getters }, text) {
      if (getters.lessonStep || !getters.online) { return }

      if (!text) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('createFeedback')
      t.start()

      const { toastify } = useNotifications()
      let toastId

      try {
        const data = {
          owner: getUid(),
          text: text.trim(),
          timestamp: new Date(),
          active: true
        }

        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        await addFirestoreDoc('feedback', data)

        toastify.replace(toastId, localizeFilter('FeedbackSaved'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createFeedback', params: { text }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async noNeedLesson({ commit, dispatch, getters }) {
      if (!getters.userStats('needsLesson') && !getters.lessonStep) { return }

      const t = tracePerformance('noNeedLesson')
      t.start()

      try {
        const batchArray = await createBatch([
          {
            timestamp: new Date(+getters.syncTimestamp - 1),
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              needsLesson: firestoreDeleteField
            },
            noLogs: true
          },
          {
            timestamp: new Date(+getters.syncTimestamp - 1),
            type: 'delete',
            place: 'logs',
            logPlace: 'userStats',
            logType: 'lesson',
            id: +getters.syncTimestamp - 1
          }
        ])

        for (const batch of batchArray) { await batch.commit() }

        commit('deleteFieldFromUserStats', 'needsLesson')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'noNeedLesson', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async goHomeAndShowLoader({ commit }) {
      await commit('setShowLoader', true)
      router.push({ name: 'Home' }).catch(() => { })
    }
  },
  getters: {
    appVersion: s => s.appVersion,
    firebaseQuotaReached: s => s.firebaseQuotaReached,
    online: s => s.online,
    scrollAllowed: s => s.scrollAllowed,
    clickedButton: s => s.clickedButton,
    dataBarIsOpen: s => s.dataBarIsOpen,
    menuIsOpen: s => s.menuIsOpen,
    processing: s => s.processing,
    maintenance: s => s.maintenance,
    maintenanceUnsubscribe: s => s.maintenanceUnsubscribe,
    sessionId: s => s.sessionId,
    helpLink: (s, getters) => {
      const locale = getters.locale
      return `${process.env.VUE_APP_APP_HELP}?lang=${locale}`
    },
    legalLink: (s, getters) => {
      const locale = getters.locale
      return `${process.env.VUE_APP_SITE_URL}/legal?lang=${locale}`
    },
    openedLink: s => s.openedLink,
    showLoader: s => s.showLoader,
    showResize: s => s.showResize,
    loadingPage: s => s.loadingPage,
    appShow: s => s.appShow,
    datepickerMinLimit: () => {
      const year = 1900
      const month = 0
      const day = 1
      return new Date(new Date(year, month, day).getUTCFullYear(), new Date(year, month, day).getUTCMonth(), new Date(year, month, day).getUTCDate())
    },
    datepickerMaxLimit: () => {
      return new Date(new Date().getFullYear() + 15, 11, 31)
    },
    loadedAll: (s) => field => {
      return s.loadedAll[field]
    }
  }
}
